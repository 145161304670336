import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import(/* webpackChunkName: "about" */ '../views/Main.vue')
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import(/* webpackChunkName: "about" */ '../views/Footer/Imprint.vue')
  },
  {
    path: '/privacypolicy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/Footer/PrivacyPolicy.vue')
  },
  {
    path: '/termsofuse',
    name: 'Terms of Use',
    component: () => import(/* webpackChunkName: "about" */ '../views/Footer/TermsOfUse.vue')
  }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

export default router