<template>
  <div class="header">
    <router-link to="/"><img src="@/assets/Logo.jpg" width="75" height="75" class="logo-img"></router-link>
  </div>
  </template>
  
  <script>
  export default {
  }
  </script>
  
<style scoped>
  
.header { 
width: 100vw;
display: flex;
justify-content: left;
}
.logo-img {
  padding:1vw 0px 0px 1vw;
  height: auto;
  width:10vw;
}
/* Media Query for devices with a max-width of 600px (adjust as needed) */

@media (max-width: 1200px) {
  .logo-img {
    width: 150px; /* Fixed size for smaller screens */
    padding: 10px; /* Adjust padding as needed */
  }
}
@media (max-width: 600px) {
  .logo-img {
    width: 100px; /* Fixed size for smaller screens */
    padding: 10px; /* Adjust padding as needed */
  }
}
</style>
  