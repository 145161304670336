<template>
<div class="footer-bg">
  <div class="footer-container">
    <ul class="menu">
      <li class="menu_item"><router-link to="/imprint" class="menu_link"> Imprint </router-link></li>
      <li class="menu_item"><router-link to="/privacypolicy" class="menu_link"> Privacy Policy </router-link></li>
      <li class="menu_item"><router-link to="/termsofuse" class="menu_link"> Terms of Use </router-link></li>
    </ul>
    <p class="menu_item"> &copy; 2024 Christoph Bohn </p>
  </div>
</div>
</template>

<script>
export default {
}
</script>

<style scoped>

.footer-bg{
  width: 100vw;
  overflow-x: hidden;
  transform: translateX(var(--transXminus));
}

.footer-container {
  width: var(--maxwidth);
  transform: translateX(var(--transXplus));
}

.menu {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.menu_item {
  margin-left: 10px;
}

.menu_item :hover{
  cursor: pointer;
}

.menu_link, p {
  text-decoration: none;
  color: rgb(0, 0, 0);
}
</style>